import { StyleProps } from "@biblioteksentralen/react";
import { positiveModulo } from "@libry-content/common";
import { SanityKeyed } from "@libry-content/types";
import { useEffect, useState } from "react";
import useHorizontalSwipe from "../../utils/useHorizontalSwipe";
import { GalleryImage } from "@libry-content/types";

const FOCUSED_IMAGE_TRANSITION_TIME_MS = 750;
export const FOCUSED_IMAGE_SLIDE_SPACING_REM = 3;

const getAdjacentImage = (
  images?: SanityKeyed<GalleryImage>[],
  image?: SanityKeyed<GalleryImage>,
  step = 1
): SanityKeyed<GalleryImage> | undefined => {
  if (!image || !images || images.length < 2) return;
  const currentFocusedImageIdx = images!.findIndex(({ _key }) => !!_key && _key === image._key);

  const newFocusedImageIdx = positiveModulo(currentFocusedImageIdx + step, images!.length);
  return images![newFocusedImageIdx];
};

const getFocusedImageNavigationStyle = (step: -1 | 1) => ({
  transform: `translateX(calc(${-step * 100}% + ${-step * FOCUSED_IMAGE_SLIDE_SPACING_REM}rem))`,
  transition: `transform ${FOCUSED_IMAGE_TRANSITION_TIME_MS}ms ease-out`,
});

const useFocusedImage = (images?: SanityKeyed<GalleryImage>[]) => {
  const [focusedImage, setFocusedImage] = useState<SanityKeyed<GalleryImage> | undefined>(undefined);
  const [navigationStyle, setNavigationStyle] = useState<StyleProps | undefined>(undefined);
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | undefined>(undefined);

  const focusImage = (image: SanityKeyed<GalleryImage>) => setFocusedImage(image);
  const unFocusImage = () => setFocusedImage(undefined);

  const previousFocusedImage = getAdjacentImage(images, focusedImage, -1);
  const nextFocusedImage = getAdjacentImage(images, focusedImage, 1);

  const navigateFocusedImage = (step: -1 | 1) => {
    setNavigationStyle(getFocusedImageNavigationStyle(step));

    const timeoutId = setTimeout(() => {
      setNavigationStyle(undefined);
      const newImage = step === -1 ? previousFocusedImage : nextFocusedImage;
      setFocusedImage(newImage);
    }, FOCUSED_IMAGE_TRANSITION_TIME_MS);

    setTimeoutId(timeoutId);
  };

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  const { onTouchStart, onTouchMove, onTouchEnd, deltaX } = useHorizontalSwipe({
    onSwipeLeft: () => navigateFocusedImage(-1),
    onSwipeRight: () => navigateFocusedImage(1),
  });

  const mobileSwipeStyle = deltaX ? { transform: `translateX(${deltaX}px)` } : undefined;

  return {
    focusImage,
    unFocusImage,
    focusedImage,
    previousFocusedImage,
    nextFocusedImage,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    navigateFocusedImage,
    focusedImageStyle: mobileSwipeStyle ?? navigationStyle,
  };
};

export default useFocusedImage;
