import { CombinedRichTextBlock } from "@libry-content/types";

type Style = "normal" | "h2" | "h3";

export type StyleMap = Partial<Record<Style, Style>>;

const blockHasStyle = (block?: CombinedRichTextBlock): block is CombinedRichTextBlock & { style: Style } =>
  typeof (block as any)?.["style"] === "string";

export const mapStyles = (
  styleMap?: StyleMap,
  blocks?: any | CombinedRichTextBlock[]
): NonNullable<CombinedRichTextBlock[]> =>
  blocks?.map((block: CombinedRichTextBlock) =>
    styleMap && blockHasStyle(block) && block.style in styleMap ? { ...block, style: styleMap[block.style] } : block
  ) ?? [];
